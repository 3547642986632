import Vue from 'vue'
import VueRouter from 'vue-router'

// 避免到当前位置的冗余导航。 简单来说就是重复触发了同一个路由
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: {
      title: '登录',
      showTabbar: false//控制底部tab是否显示
    }
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('../views/welcome.vue'),
    meta: {
      title: '欢迎页',
      showTabbar: false
    }
  },
  {
    path: '/previousInfo',
    name: 'previousInfo',
    component: () => import('../views/previousInfo.vue'),
    meta: {
      title: '入职登记',
      showTabbar: false
    }
  },
  {
    path: '/nextInfo',
    name: 'nextInfo',
    component: () => import('../views/nextInfo.vue'),
    meta: {
      title: '入职登记',
      showTabbar: false
    }
  },
  {
    path: '/myInfo',
    name: 'myInfo',
    component: () => import('../views/myInfo.vue'),
    meta: {
      title: '我的信息',
      showTabbar: false
    }
  },
  {
    path: '/mySalary',
    name: 'mySalary',
    component: () => import('../views/mySalary.vue'),
    meta: {
      title: '我的薪资',
      showTabbar: true
    }
  },
  {
    path: '/salaryInfo',
    name: 'salaryInfo',
    component: () => import('../views/salaryInfo.vue'),
    meta: {
      title: '薪资详情',
      showTabbar: false
    }
  },
  {
    path: '/mySocial',
    name: 'mySocial',
    component: () => import('../views/mySocial.vue'),
    meta: {
      title: '我的社保',
      showTabbar: true
    }
  },
  {
    path: '/socialInfo',
    name: 'socialInfo',
    component: () => import('../views/socialInfo.vue'),
    meta: {
      title: '社保详情',
      showTabbar: false
    }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('../views/confirm.vue'),
    meta: {
      title: '信息确认',
      showTabbar: true
    }
  },
  {
    path: '/qianyueList',
    name: 'qianyueList',
    component: () => import('../views/qianyueList.vue'),
    meta: {
      title: '我的签约',
      showTabbar: true
    }
  },
  {
    path: '/addFankui',
    name: 'addFankui',
    component: () => import('../views/addFankui.vue'),
    meta: {
      title: '问题反馈',
      showTabbar: true
    }
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/mine.vue'),
    meta: {
      title: '我的',
      showTabbar: true
    }
  },
  {
    path: '/fankui',
    name: 'fankui',
    component: () => import('../views/fankui.vue'),
    meta: {
      title: '我的反馈',
      showTabbar: true
    }
  },
  {
    path: '/fankuiInfo',
    name: 'fankuiInfo',
    component: () => import('../views/fankuiInfo.vue'),
    meta: {
      title: '反馈详情',
      showTabbar: true
    }
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/message.vue'),
    meta: {
      title: '消息',
      showTabbar: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',// 去掉url中的#
  routes
})

export default router
