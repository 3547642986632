import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'amfe-flexible'
Vue.config.productionTip = false
import Vant from 'vant';
import 'vant/lib/index.css';
import "./assets/css/iconfont.css";//引入iconfont
import axios from 'axios';
Vue.prototype.$axios = axios;
axios.defaults.baseURL = 'https://personalapi.richfun.cn';
// axios.defaults.baseURL = 'http://personnelapi.hrot.com';

//接口
/** 实名信息接口
 * http://personnelapi.hrot.com/person/getperstatusz
 * 
 * 
 * */

Vue.use(Vant);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
// 动态设置vue页面title
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
});
