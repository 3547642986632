<template>
  <div id="app">
    <router-view />
    <tab v-show="$route.meta.showTabbar"></tab>
  </div>
</template>
<script>
import tab from '@/components/tab'
export default {
  components:{
    tab
  }
}
</script>
<style>
</style>
