<template>
  <div class="mytab">
    <!-- <van-tabbar v-model="active" @change="onChange">
      <van-tabbar-item icon="chat-o" name="message">消息</van-tabbar-item>
      <van-tabbar-item icon="contact" name="mine">我的</van-tabbar-item>
    </van-tabbar> -->
    <div class="tabitem" v-for="(item,index) in tabList" :key="index" @click="toTab(item)">
      <div><span :class="item.icon"></span></div>
      <div>{{item.value}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: "",
      tabList:[
        {name:'message',value:'消息',icon:'iconfont icon-xiaoxi'},
        {name:'mine',value:'我的',icon:'iconfont icon-wode'},
      ]
    };
  },
  methods: {
    onChange(val) {
      if (val == "message") {
        this.$router.push({ name: "message" });
      } else if (val == "mine") {
        this.$router.push({ name: "mine" });
      }
    },
    toTab(val){
      this.$router.push({name:val.name})
    }
  },
};
</script>
<style>
.mytab .van-tabbar {
  height: 3.5rem !important;
}
.mytab .van-tabbar-item {
  font-size: 1rem !important;
}
.mytab .van-tabbar-item__icon {
  font-size: 0.8rem !important;
}
.mytab .van-icon {
  font-size: 1.5rem !important;
}
.mytab{
  width:100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom:0;
  left:0;
  border-top:1px solid #eee;
  padding-top:0.5rem;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  background-color: #fff;

}
.tabitem{
  width:100%;
  height:2.5rem;
  text-align: center;
  /* line-height: 3.5rem; */
}
.mytab .iconfont{
  font-size: 1.3rem;
}
</style>